import React from 'react'
import { graphql } from 'gatsby'

import Template from '../templates/defaultTemplate'

const IndexPage = ({ data }) => (
  <Template data={data} />
)

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { path: { eq: "/" } }) {
      html
      frontmatter {
        path
        title
        menu
        menuPosition
      }
    }
  }
`

export default IndexPage
